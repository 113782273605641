<script lang="ts">
  import { DialogContent } from '../../state';
  import PopOver from '../PopOver.svelte';
  import Accordion from './accordion/Accordion.svelte';
</script>

<PopOver
  className="max-h-screen-2/3 overflow-y-auto"
  content={DialogContent.NavigationMenu}
>
  <nav class="w-full">
    <Accordion />
  </nav>
</PopOver>
