<script lang="ts">
  import InfoBox from '@svelte/components/atoms/info/Box.svelte';
  import Paragraph from '@svelte/components/atoms/typography/Paragraph/Paragraph.svelte';
  import { ErrorNotification } from '@svelte/lib/notifications/types';
  import Dialog from '../../Dialog.svelte';

  export let error: ErrorNotification;
</script>

<Dialog padding="" bg="">
  <InfoBox level={error.level} withBorders className="rounded-md">
    <Paragraph withoutSpacing>
      {error.content}
    </Paragraph>
  </InfoBox>
</Dialog>
