<script context="module" lang="ts">
  function textSize(level: number) {
    return level === 2 ? TextSize.BodyCopy : TextSize.LongPrimer;
  }
</script>

<script lang="ts">
  import Balancer from '@svelte/components/atoms/balancer/Balancer.svelte';
  import InterfaceText from '@svelte/components/atoms/typography/InterfaceText/InterfaceText.svelte';
  import { TextSize } from '@svelte/components/atoms/typography/props';
  import Link from '@svelte/sveltify/Link.svelte';
  import type * as SchemaCMS from '@svelte/service/cms/graphql/gql';
  import { makeClassNames } from 'lib/util';
  import { DialogContent, activeDialog } from '../../../state';

  export let item: SchemaCMS.NavigationMenuItemFragment;
  export let level: number;
  export let className: string | null = null;
</script>

<Link
  to={item.path || ''}
  role="menuitem"
  className={makeClassNames(
    'block',
    level > 0 ? 'py-rhythm-2 pl-4' : className
  )}
  onClick={() => activeDialog.set(DialogContent.None)}
>
  <InterfaceText size={textSize(level)} bold={level === 0}>
    <Balancer id={item.title}>{item.title.toUpperCase()}</Balancer>
  </InterfaceText>
</Link>
