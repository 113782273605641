<script context="module" lang="ts">
  const colorsTransition = 'transition-colors duration-500';

  const unselectedClassNames = [
    'disabled:border-pearl-600',
    'disabled:bg-pearl-600',
    'disabled:text-pearl-800',
    'disabled:cursor-not-allowed'
  ];
</script>

<script lang="ts">
  import { IS_DEVELOPMENT_ENV, makeClassNames } from 'lib/util';
  import InterfaceText from '@svelte/components/atoms/typography/InterfaceText/InterfaceText.svelte';
  import { TextSize } from '@svelte/components/atoms/typography/props';
  import {
    productSkuSelectionById,
    setSkuSelection
  } from 'state/stores/product';

  import { StrapiSkuFragment } from 'gatsby/graphqlTypes';
  import FormattedPrice from '@svelte/components/atoms/product/FormattedPrice/FormattedPrice.svelte';

  export let productId: number;
  export let sku: StrapiSkuFragment;

  if (IS_DEVELOPMENT_ENV && sku.soldBy.toLowerCase() !== 'weight') {
    throw Error('Unsupported soldBy unit');
  }

  const selectedSku = productSkuSelectionById(productId);
  $: isSelected = $selectedSku?.id === sku.id;
</script>

<button
  disabled={!sku.inStock}
  on:click={() => setSkuSelection(productId, sku)}
  class={makeClassNames(
    'flex',
    colorsTransition,
    'w-full',
    'items-center',
    'rounded-md',
    'justify-between',
    'border-2',
    'p-2',
    'px-4',
    'border-primary',
    isSelected && ['bg-primary', 'text-secondary'],
    sku.inStock ? ['border-primary'] : unselectedClassNames
  )}
>
  <div class="flex items-center space-x-3">
    <input
      type="radio"
      readonly
      checked={isSelected}
      value={sku.id}
      disabled={!sku.inStock}
      class={makeClassNames(
        'h-4 w-4 text-secondary checked:border-primary-300 focus:ring-primary',
        colorsTransition,
        !sku.inStock && 'disabled:bg-pearl-600'
      )}
    />
    <div class="flex flex-col items-start">
      <div class="-mt-1 flex items-baseline">
        <InterfaceText size={TextSize.Pica} color="">
          {sku.weight}
        </InterfaceText>

        <InterfaceText size={TextSize.LongPrimer} color="" class="ml-0.5">
          {sku.weight_unit?.name}
        </InterfaceText>
      </div>

      {#if !sku.inStock}
        <InterfaceText size={TextSize.LongPrimer} color="" bold
          >Out of stock</InterfaceText
        >
      {:else if typeof sku.uses === 'number'}
        <InterfaceText size={TextSize.Brevier} color=""
          >Up to {sku.uses} uses</InterfaceText
        >
      {/if}
    </div>
  </div>

  <InterfaceText
    color=""
    class={makeClassNames('flex flex-col', isSelected && 'text-secondary')}
  >
    <span class="sr-only">Price </span>
    <FormattedPrice priceIntegerWithDecimals={sku.priceInt} />
  </InterfaceText>
</button>
