<script lang="ts">
  import AddToCartNotification from '@svelte/features/comms-menu/components/notifications/add-to-cart/AddToCartNotification.svelte';
  import Dialog from '../../Dialog.svelte';
  import { AddedToCartNotification } from '@svelte/lib/notifications/types';

  export let notification: AddedToCartNotification | null = null;
</script>

{#if notification}
  <Dialog className="border-4 border-primary-700">
    <AddToCartNotification
      on:navigation
      data={notification}
      withAddToCartButton={false}
      className="px-rhythm1"
      layout="h"
    />
  </Dialog>
{/if}
