<!-- Inspired by: https://svelte.dev/repl/782718a4f2fe43e3b731822b54aaed78?version=3.38.3 -->

<script context="module" lang="ts">
  const sideClassNames =
    'absolute w-full h-full flex justify-center items-center';
</script>

<script lang="ts">
  import { makeClassNames } from 'lib/util';

  // Background colour is required because
  // the root container has a bg of transparency to allow for
  // 3D animation to show
  export let frontBg: string;
  export let backBg = frontBg;
  export let flipped: boolean | null = null;
  export let className: string | null = null;
  export let duration = 'duration-500';
  export let frontClassNames: string | null = null;
  export let backClassNames: string | null = null;
  export let withoutShadows = false;

  let internalFlipped = false;
  $: showBack = flipped === null ? internalFlipped : flipped;

  function flip() {
    internalFlipped = !internalFlipped;
  }
</script>

<div class={makeClassNames('bg-transparent', className)} on:click on:keydown>
  <div
    class:flipped={showBack}
    class={makeClassNames('inner relative transform w-full h-full', duration)}
    on:click={flip}
    on:keydown={flip}
  >
    <div
      class={makeClassNames(
        'front',
        !withoutShadows && 'transition-shadow',
        withoutShadows
          ? null
          : showBack
          ? 'shadow-none'
          : 'shadow shadow-primary',
        sideClassNames,
        frontBg,
        frontClassNames,
        duration
      )}
    >
      <slot name="front" />
    </div>
    <div class={makeClassNames('back', sideClassNames, backBg, backClassNames)}>
      <slot name="back" />
    </div>
  </div>
</div>

<style>
  .inner {
    transform-style: preserve-3d;
  }

  .flipped {
    transform: rotateY(180deg);
  }

  .front,
  .back {
    backface-visibility: hidden;
    gap: 0.5rem;
  }

  .back {
    transform: rotateY(180deg);
  }
</style>
