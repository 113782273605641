<script lang="ts">
  import { makeClassNames } from 'lib/util';

  // Source: https://jsfiddle.net/psullivan6/0eL3jezk/

  type State = 'plus' | 'minus';

  export let dimensions: string;
  export let state: State;
  export let color: string = 'before:bg-primary-900 after:bg-primary-900';
  export let className: string | null = null;
</script>

<div
  class={makeClassNames('relative', className, color, dimensions)}
  class:minus={state === 'minus'}
/>

<style lang="scss">
  div {
    /* position: relative; */
    width: 15px;
    height: 15px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      /* background-color: white; */
      transition: transform 0.25s ease-out;
    }

    /* Vertical line */
    &:before {
      left: 0;
      right: 0;
      width: 2px;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    /* horizontal line */
    &:after {
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      margin-top: -1.5px;
    }

    &.minus {
      &:before {
        transform: rotate(90deg);
      }
      &:after {
        transform: rotate(180deg);
      }
    }
  }
</style>
