<script lang="ts">
  import { makeClassNames } from 'lib/util';
  import { fly } from 'svelte/transition';

  export let className: string | null = null;
  export let padding = 'p-rhythm-2 pb-9';
  export let bg = 'bg-pearl-200';
</script>

<dialog
  transition:fly={{ y: 100 }}
  open
  class="absolute bottom-0 w-screen bg-transparent px-rhythm-1 sm:w-96"
>
  <div
    class={makeClassNames(
      'material-shadow-4 rounded-md',
      className,
      padding,
      bg
    )}
  >
    <slot />
  </div>
</dialog>
