import { Readable, writable } from 'svelte/store';
import { DisruptorServiceWorkerAsync } from '../worker/types';
import { canUseDom } from 'lib/util';
import type {
  CombinedError,
  Operation,
  OperationContext,
  OperationResult
} from '@urql/core';
import { DisruptorExtensions } from '../backend/graphql/extensions';
import * as Comlink from 'comlink';
import {
  DisruptorCmsRequestKey,
  GraphQLOutputCMS,
  VariablesCMS
} from './types';
import * as BackendQueryStore from '../backend/queryStore';

export type QueryArgsCMS<T extends DisruptorCmsRequestKey> = Readonly<{
  key: T;
  worker: DisruptorServiceWorkerAsync;
  context?: Partial<OperationContext>;
  variables: VariablesCMS<T>;
}>;

export type DisruptorCombinedError = CombinedError & {
  disruptorExtensions: DisruptorExtensions;
};

export type DisruptorOperationResultStateCMS<T extends DisruptorCmsRequestKey> =
  Omit<OperationResult<GraphQLOutputCMS<T>, void>, 'error' | 'operation'> & {
    operation?: Operation<GraphQLOutputCMS<T>, void>;
    error?: DisruptorCombinedError;
    fetching: boolean;
  };

export function parseOpResult<T extends DisruptorCmsRequestKey>(
  result: OperationResult<GraphQLOutputCMS<T>, void>
): DisruptorOperationResultStateCMS<T> {
  // TODO: make generic functions for the two services
  return BackendQueryStore.parseOpResult(
    //@ts-expect-error
    result
  ) as DisruptorOperationResultStateCMS<T>;
}

export function makeInitialFetchingResult<
  T extends DisruptorCmsRequestKey
>(): DisruptorOperationResultStateCMS<T> {
  return BackendQueryStore.makeInitialFetchingResult() as DisruptorOperationResultStateCMS<T>;
}

export function makeInitialNonFetchingResult<
  T extends DisruptorCmsRequestKey
>(): DisruptorOperationResultStateCMS<T> {
  return BackendQueryStore.makeInitialNonFetchingResult() as DisruptorOperationResultStateCMS<T>;
}

export function cmsQueryStore<T extends DisruptorCmsRequestKey>({
  context,
  key,
  worker,
  variables
}: QueryArgsCMS<T>): Readable<DisruptorOperationResultStateCMS<T>> {
  if (!canUseDom()) {
    return writable(makeInitialFetchingResult<T>());
  }

  const store = writable(makeInitialFetchingResult<T>(), set => {
    let subId: string | undefined;
    worker
      .query(
        {
          key,
          variables
        },

        Comlink.proxy(result => {
          set(
            parseOpResult(result as OperationResult<GraphQLOutputCMS<T>, void>)
          );
        }),
        context
      )
      .then(id => {
        subId = id;
      });

    return () => {
      if (subId) {
        worker.unsubscribe(subId);
      }
    };
  });

  return store;
}
