<script lang="ts">
  import { type InterfaceTextSize } from '../typography/props';
  import DisclosureButton from './DisclosureButton.svelte';
  import { makeClassNames } from 'lib/util';

  export let isOpen = false;
  export let level: number;
  export let name: string;
  export let buttonTextSize: InterfaceTextSize | undefined = undefined;
  export let className: string | undefined = undefined;
  let contentsId = `${name.toLowerCase()}-menu`;

  const toggle = () => (isOpen = !isOpen);
</script>

<!-- NOTE: this is not a11y compliant -->
<!-- TODO: remove and use animatedAction in this folder with details/summary HTML elements. See SummaryDisclosure in checkout mobile for example -->

<DisclosureButton
  {level}
  {name}
  {isOpen}
  textSize={buttonTextSize}
  ariaControls={contentsId}
  on:click={toggle}
/>
<!-- NOTE: for some reason this transition is janky -->
<!-- {#if isOpen}
    <div id={contentsId} transition:slide>
      <slot />
    </div>
  {/if} -->
<div
  id={contentsId}
  class={makeClassNames('overflow-hidden transition-max-height', className)}
  style:max-height={isOpen ? `1000px` : '0px'}
>
  <slot />
</div>
