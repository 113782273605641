<script lang="ts">
  import CircleSpinner from '@svelte/components/atoms/icons/CircleSpinner.svelte';
  import {
    ChatStatus,
    chatState,
    isChatWidgetOpen
  } from '@svelte/lib/integrations/chatwoot/stores';
  import ChatIcon from '@svelte/components/atoms/icons/Chat.svelte';
  import { derived } from 'svelte/store';

  const shouldShowChat = derived(isChatWidgetOpen, open => !open);
</script>

{#if $chatState === ChatStatus.Loading}
  <div class="h-full w-full p-3">
    <CircleSpinner />
  </div>
{:else if shouldShowChat}
  <ChatIcon className="w-6 h-6" />
{/if}
