<script lang="ts">
  import { makeClassNames } from 'lib/util';
  import { fade } from 'svelte/transition';
  import NotificationHandler from '@svelte/lib/notifications/notificationHandler';
  import {
    buttonClassNames,
    buttonBorderClassNames,
    bgColour
  } from '../styles';
  import { derived } from 'svelte/store';
  import { Breakpoint, windowDimensions } from 'lib/device/state';
  import {
    ErrorNotification,
    NotificationKind
  } from '@svelte/lib/notifications/types';
  import { isActiveDialogOpen } from '../../state';

  export let withCountdown = true;
  export let handler: NotificationHandler;

  const radius = derived(windowDimensions, ({ width }) => {
    return width >= Breakpoint.SM ? 26 : 23;
  });
  const circleOffset = derived(windowDimensions, ({ width }) => {
    return width >= Breakpoint.SM ? 29 : 26;
  });
  const notification = handler.nextNotification$;

  let animationId: string | null = null;
  let animateClassName = '';
  let animationSecs = 3;

  $: circumference = $radius * 2 * Math.PI;
  $: notificationId = $notification?.id;
  $: errorLevel = (($notification as ErrorNotification) || null)?.level;
  $: show = !!notificationId && !$isActiveDialogOpen;
  $: {
    if (withCountdown) {
      if ($notification?.kind === NotificationKind.NewsletterPrompt) {
        animationSecs = 8;
      } else {
        animationSecs = 3;
      }

      if (notificationId && notificationId !== animationId) {
        animationId = notificationId;
        // Restart animation
        animateClassName = '';
        // HACK: is there a better way for sync updates?
        setTimeout(() => {
          animateClassName = 'animate-[strokeOffsetToZero_linear_forwards]';
        }, 100);
      }
    } else {
      animationId = null;
    }
  }

  function handleDismissal() {
    notificationId && handler.dismissById(notificationId);
  }

  function handleTimeOut() {
    notificationId && handler.timeOutById(notificationId);
  }
</script>

{#if show}
  <button
    in:fade={{ duration: 300 }}
    out:fade={{ duration: 200 }}
    type="button"
    on:click={handleDismissal}
    class={makeClassNames(
      buttonClassNames,
      withCountdown ? 'border' : buttonBorderClassNames,
      bgColour(errorLevel)
    )}
  >
    <div class="relative flex h-full w-full items-center justify-center">
      {#if withCountdown}
        <svg class="absolute inset-0 h-full w-full -rotate-90">
          <title>Dismissal counter</title>
          <circle
            stroke-width="5"
            stroke="currentColor"
            fill="transparent"
            class={makeClassNames(animateClassName)}
            r={$radius}
            cx={$circleOffset}
            cy={$circleOffset}
            stroke-dasharray={circumference}
            stroke-dashoffset={circumference}
            on:animationend={handleTimeOut}
            style:animation-duration={`${animationSecs}s`}
          />
        </svg>
      {/if}

      <div class="text-f0 font-semibold">X</div>
    </div>
  </button>
{/if}
