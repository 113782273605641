<script lang="ts">
  import Balancer from '@svelte/components/atoms/balancer/Balancer.svelte';
  import PlusMinusIcon from '@svelte/components/atoms/icons/plus-minus/PlusMinusIcon.svelte';
  import InterfaceText from '@svelte/components/atoms/typography/InterfaceText/InterfaceText.svelte';
  import { type InterfaceTextSize } from '@svelte/components/atoms/typography/props';

  export let level: number;
  export let name: string;
  export let isOpen = false;
  export let ariaControls: string;
  export let textSize: InterfaceTextSize | undefined;
</script>

<button
  class="flex w-full items-center justify-between text-left"
  role="menuitem"
  aria-expanded={isOpen}
  aria-haspopup="true"
  aria-controls={ariaControls}
  on:click
>
  <InterfaceText bold={level === 0} size={textSize}>
    <Balancer id={`disc-${name}`}>{name.toUpperCase()}</Balancer>
  </InterfaceText>
  <PlusMinusIcon
    dimensions="w-rhythm-1 h-rhythm-1"
    state={isOpen ? 'minus' : 'plus'}
  />
</button>
