<script lang="ts">
  import AccordionItem from './AccordionItem.svelte';
  import { getContextGraphqlWorker } from '@svelte/service/worker/context/context';
  import { cmsQueryStore } from '@svelte/service/cms/queryStore';

  const queryWorker = getContextGraphqlWorker();
  const mobileNavigationQuery = cmsQueryStore({
    key: 'navigationMenu',
    worker: queryWorker,
    variables: {
      id: '3'
    }
  });
  $: level0Items =
    $mobileNavigationQuery.data?.renderNavigation[0]?.items || [];
</script>

<ul role="menu" class="w-full">
  {#each level0Items as item}
    <AccordionItem {item} />
  {/each}
</ul>
