<script lang="ts">
  import PopOver from '../PopOver.svelte';
  import { DialogContent, activeDialog } from '../../state';
  import { currentProductPageData } from 'state/stores/nav';
  import Heading from '@svelte/components/atoms/typography/Heading/Heading.svelte';
  import TextButton from '@svelte/components/atoms/button/text/TextButton.svelte';
  import SizeSelector from '@svelte/components/molecules/product/size-selector/SizeSelector.svelte';
  import { makeQueryPromise } from '@svelte/service/backend/queryStore';
  import { svelteAddToLocalCart } from 'state/stores/checkout';
  import { getContextGraphqlWorker } from '@svelte/service/worker/context/context';
  import { getAppEventDispatcher } from '@svelte/lib/events/context';
  import {
    SkuSelectionError,
    productSkuSelectionById,
    skuSelectionErrorStore
  } from 'state/stores/product';
  import { get } from 'svelte/store';
  import { TextCase } from '@svelte/components/atoms/typography/props';

  const queryWorker = getContextGraphqlWorker();
  const appEventDispatcher = getAppEventDispatcher();
  $: currentSkuSelection = productSkuSelectionById($currentProductPageData?.id);

  $: productId = $currentProductPageData?.id;
  $: skus = $currentProductPageData?.skus || [];

  async function handleAddToCart() {
    const selectedSku = get(currentSkuSelection);

    if (selectedSku) {
      const allProducts = await makeQueryPromise({
        key: 'allProducts',
        variables: {},
        worker: queryWorker
      });

      activeDialog.set(DialogContent.None);

      svelteAddToLocalCart({
        products: allProducts,
        productId: $currentProductPageData?.id,
        skuId: selectedSku.id,
        appEventTarget: appEventDispatcher,
        shouldNotify: true
      });
    } else {
      skuSelectionErrorStore.set({
        error: SkuSelectionError.NoWeightSelected,
        notification: true
      });
    }
  }
</script>

<PopOver content={DialogContent.SelectProductSku}>
  <div class="px-rhythm1">
    <Heading level={3} textCase={TextCase.Uppercase} withoutSpacing
      >{$currentProductPageData?.name}</Heading
    >
    <Heading level={4}>Choose One Size</Heading>
    {#if productId}
      <SizeSelector {productId} {skus} />
    {/if}

    <TextButton on:click={handleAddToCart} size="s" text="ADD TO CART" />
  </div>
</PopOver>
