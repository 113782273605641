<script context="module" lang="ts">
  const ITEM_CLASS_NAME = 'border-b last:border-0 border-primary';
</script>

<script lang="ts">
  import Disclosure from '@svelte/components/atoms/disclosure/Disclosure.svelte';
  import type * as SchemaCMS from '@svelte/service/cms/graphql/gql';
  import Link from '@svelte/sveltify/Link.svelte';
  import NavLink from './NavLink.svelte';
  import LaunchIcon from 'carbon-icons-svelte/lib/Launch.svelte';
  import { makeClassNames } from 'lib/util';
  import { DialogContent, activeDialog } from '../../../state';
  import { TextSize } from '@svelte/components/atoms/typography/props';

  export let item: SchemaCMS.NavigationMenuQuery['renderNavigation'][0];
  export let level = 0;
  let itemClassName = level === 0 ? ITEM_CLASS_NAME : null;
</script>

{#if item}
  {#if !item.items?.length}
    <li class={itemClassName}>
      <NavLink {item} {level} className="py-rhythm1" />
    </li>
  {:else}
    <li
      class={makeClassNames(
        itemClassName,
        level === 0 ? 'py-rhythm1' : 'py-rhythm-2'
      )}
    >
      <Disclosure
        name={item.title}
        {level}
        buttonTextSize={TextSize.LongPrimer}
      >
        {#if item.menuAttached}
          <Link
            to={item.path || ''}
            role="menuitem"
            className="inline-block my-rhythm-2"
            onClick={() => activeDialog.set(DialogContent.None)}
          >
            <!-- TODO: use <InterfaceText> when removing React Link-->
            <span class="text-f-1">See all</span><LaunchIcon
              class="mb-0.5 ml-1 inline"
              width={16}
              height={16}
            /></Link
          >
        {/if}

        <div
          class={makeClassNames('px-2 py-rhythm-2', level > 0 && 'bg-pearl')}
        >
          {#each item?.items || [] as child (child?.id)}
            <svelte:self item={child} level={level + 1} />
          {/each}
        </div>
      </Disclosure>
    </li>
  {/if}
{/if}
