<script lang="ts">
  import TextButton from '@svelte/components/atoms/button/text/TextButton.svelte';
  import { activeDialog, DialogContent } from '../../state';

  const handleGuidedFlow = () => activeDialog.set(DialogContent.GuidedCartFlow);
</script>

<TextButton
  on:click={handleGuidedFlow}
  text="Get Yours Now"
  className="w-3/4 absolute"
/>
