import { NotificationErrorLevel } from '@svelte/lib/notifications/types';
import { assertUnreachable } from 'lib/util';

export const buttonClassNames = [
  'h-12 w-12',
  'rounded-full',
  'text-f1 font-semibold',
  'border-pearl-800'
];

export const buttonBorderClassNames = ['border-2'];

export function bgColour(level?: NotificationErrorLevel): string[] {
  switch (level) {
    case NotificationErrorLevel.Error:
      return ['bg-red-500', 'text-pearl-100'];
    case NotificationErrorLevel.Success:
      return ['bg-emerald-500', 'text-pearl-100'];
    case NotificationErrorLevel.Warn:
      return ['bg-orange-500', 'text-pearl-100'];
    case NotificationErrorLevel.Info:
    case undefined:
      return ['bg-primary', 'text-secondary'];
    default:
      assertUnreachable(level);
  }
}
