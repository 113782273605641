<script lang="ts">
  import {
    SkuSelectionError,
    productSkuSelectionById,
    skuSelectionErrorStore
  } from 'state/stores/product';
  import ProductSizeButton from '../size/ProductSizeButton.svelte';
  import { makeClassNames } from 'lib/util';
  import { StrapiSkuFragment } from 'gatsby/graphqlTypes';
  import InterfaceText from '@svelte/components/atoms/typography/InterfaceText/InterfaceText.svelte';
  import { TextSize } from '@svelte/components/atoms/typography/props';
  import ScalesIcon from '@svelte/components/atoms/icons/Scales.svelte';

  export let productId: number;
  export let skus: StrapiSkuFragment[];

  $: currentSkuSelection = productSkuSelectionById(productId);
  $: hasError =
    $skuSelectionErrorStore.error === SkuSelectionError.NoWeightSelected;
  $: weightSelectionAvailable = skus.length > 1 && skus[0].soldBy !== 'unique';
</script>

{#if weightSelectionAvailable}
  <fieldset class="border-t border-primary-200 py-rhythm0">
    <div>
      <legend class="flex items-center space-x-2">
        <ScalesIcon className="fill-primary w-rhythm2" />
        <InterfaceText>Weight</InterfaceText>
        {#if skus.length > 1 && !$currentSkuSelection}
          <InterfaceText
            size={TextSize.LongPrimer}
            bold={hasError}
            className={makeClassNames(
              'block origin-center',
              $skuSelectionErrorStore.notification && 'animate-shake-x'
            )}
            on:animationend={() => {
              skuSelectionErrorStore.update(value => ({
                error: value.error,
                notification: false
              }));
            }}
          >
            (To buy, select one)
          </InterfaceText>
        {/if}
      </legend>
    </div>

    <div
      class="mt-3 flex flex-col gap-rhythm0 lg:grid lg:grid-cols-[repeat(auto-fill,minmax(0,13rem))]"
    >
      {#each skus as sku}
        <ProductSizeButton {productId} {sku} />
      {/each}
    </div>
  </fieldset>
{/if}
