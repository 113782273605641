<script lang="ts">
  import { DialogContent } from '../../state';
  import PopOver from '../PopOver.svelte';
  import Heading from '@svelte/components/atoms/typography/Heading/Heading.svelte';
  import {
    TextCase,
    TextSize
  } from '@svelte/components/atoms/typography/props';
  import InterfaceText from '@svelte/components/atoms/typography/InterfaceText/InterfaceText.svelte';
  import FormattedPrice from '@svelte/components/atoms/product/FormattedPrice/FormattedPrice.svelte';
  import Paragraph from '@svelte/components/atoms/typography/Paragraph/Paragraph.svelte';
  import { useAppNavTree } from '@svelte/data/routes/context';
  import { makeProductPath } from 'lib/navigation/dynamic';
  import LinkButton from '@svelte/components/atoms/button/link/LinkButton.svelte';
  import { createEventDispatcher } from 'svelte';
  import ProductThumbnail from '@svelte/components/atoms/image/product-thumbnail/ProductThumbnail.svelte';
  import { staticProductData } from '@svelte/data/product/staticProductData';
  import ProductRatingInline from '@svelte/components/molecules/product/rating-inline/ProductRatingInline.svelte';
  import { productQuickViewSelectedStore } from '@svelte/state/productQuickView';

  const appNavTree = useAppNavTree();
  const dispatch = createEventDispatcher();

  $: product = $productQuickViewSelectedStore;
  $: minPrice = product?.minPrice || 0;
  $: maxPrice = product?.maxPrice || 0;
  $: thumbnail = product && staticProductData[product.id];
</script>

{#if product}
  <PopOver content={DialogContent.QuickProductView}>
    <div>
      {#if thumbnail}
        <div class="mb-rhythm-1 flex h-rhythm7 w-full sm:w-rhythm9">
          <ProductThumbnail productName={product.name} {...thumbnail} />
        </div>
      {/if}
      <div
        class="mb-rhythm-1 max-h-rhythm10 overflow-y-auto border-4 border-double border-pearl-700 p-rhythm-2"
      >
        <div>
          <Heading
            level={3}
            size={TextSize.Pica}
            withoutSpacing
            textCase={TextCase.None}
          >
            {product.name}
          </Heading>
        </div>

        <Paragraph bold color="text-primary" withoutSpacing>
          {product.familyDescriptor}
        </Paragraph>

        <ProductRatingInline
          sku={product.id}
          preloadedData={product.review}
          withoutExternalLink
        />
        <InterfaceText className="mt-rhythm-3 block">
          {#if minPrice !== maxPrice}
            <span class="mr-1 text-xs">From</span>
          {/if}
          <FormattedPrice priceIntegerWithDecimals={minPrice} />
        </InterfaceText>

        <Paragraph className="mt-rhythm0 mb-rhythm-1" withoutSpacing>
          {product.descriptor}
        </Paragraph>
        <Paragraph measure="measure" size={TextSize.LongPrimer}>
          {product.shortDescription}
        </Paragraph>
      </div>
      <LinkButton
        on:click={() => dispatch('navigation')}
        to={makeProductPath($appNavTree, product.pageMetadata.slug)}
        size="s">See product details</LinkButton
      >
    </div>
  </PopOver>
{/if}
