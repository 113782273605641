<script lang="ts">
  import { fade } from 'svelte/transition';
  import { DialogContent, activeDialog, isActiveDialogOpen } from '../state';

  const close = () => activeDialog.set(DialogContent.None);
</script>

{#if $isActiveDialogOpen}
  <div
    transition:fade={{ duration: 200 }}
    aria-hidden={true}
    on:click={close}
    class="fixed inset-0 z-60 h-full w-full bg-primary-300/60 backdrop-blur lg:hidden"
  />
{/if}
