<script lang="ts">
  import ChatwootIntegration from '@svelte/lib/integrations/chatwoot/iframe/ChatwootIntegration.svelte';
  import { onDestroy, onMount } from 'svelte';
  import MenuIcon from '@svelte/components/atoms/icons/Menu.svelte';
  import CartIcon from '@svelte/components/atoms/icons/cart/Cart.svelte';
  import { makeClassNames } from 'lib/util';
  import MainCTA from './buttons/main-cta/MainCTA.svelte';
  import {
    DialogContent,
    activeDialog,
    isActiveDialogOpen,
    mobileNavigationLoaded
  } from './state';
  import { currentRouteKeyStore } from 'state/stores/nav';
  import { MAIN_NAVIGATION_ROUTER_KEY } from 'lib/globalConfig';
  import AddToCartButton from './buttons/add-to-cart/AddToCartButton.svelte';
  import CartPopover from './popover/cart/CartPopOver.svelte';
  import DialogBackdrop from './popover/DialogBackdrop.svelte';
  import DismissButton from './buttons/dismiss/DismissButton.svelte';
  import { fly, scale } from 'svelte/transition';
  import SkuSelectorPopOver from './popover/select-sku/SkuSelectorPopOver.svelte';
  import DismissNotificationButton from './buttons/dismiss-notification/DismissNotificationButton.svelte';
  import NotificationHandler from '@svelte/lib/notifications/notificationHandler';
  import { derived } from 'svelte/store';
  import NotificationPopOver from './popover/notifications/NotificationPopOver.svelte';
  import { getAppEventDispatcher } from '@svelte/lib/events/context';
  import { AppEventName, type CommsMenuDevice } from 'lib/events/contracts';
  import QuickProductViewPopOver from './popover/quick-product-view/QuickProductViewPopOver.svelte';
  import { productQuickViewSelectedStore } from '@svelte/state/productQuickView';
  import NavigationPopOver from './popover/navigation/NavigationPopOver.svelte';
  import { cmsQueryStore } from '@svelte/service/cms/queryStore';
  import { getContextGraphqlWorker } from '@svelte/service/worker/context/context';
  import GuidedCartButton from './buttons/guided-cart/GuidedCartButton.svelte';
  import GuidedCartPopOver from './popover/guided-cart/GuidedCartPopOver.svelte';
  import type { GuidedCartContent } from '../components/guided-cart/types';

  enum RouteVariant {
    None = 0,
    Default = 1,
    Product = 2,
    GuidedCartFlow = 3
  }

  export let guidedCartContent: GuidedCartContent;
  export let appEventDispatcher = getAppEventDispatcher();
  const queryWorker = getContextGraphqlWorker();
  const mobileNavigationQuery = cmsQueryStore({
    key: 'navigationMenu',
    worker: queryWorker,
    variables: {
      id: '3'
    }
  });
  const visible = derived(mobileNavigationQuery, x => !!x.data);
  const commsDevice: CommsMenuDevice = 'mobile';
  const notificationHandler = new NotificationHandler(appEventDispatcher);
  const activeNotification = derived(
    notificationHandler.allNotifications$,
    xs => xs[0]
  );

  const commonButtonClassNames = 'absolute';
  const secondaryButtonClassNames = 'relative z-10';
  let notificationDismissible = true;

  let variant = RouteVariant.Default;
  const shouldDisplayButtons = derived(
    [isActiveDialogOpen, activeDialog, activeNotification],
    // Don't shrunk with chat so that loading spinner within button shows correctly
    ([isOpen, dialogContent, activeNotification]) =>
      (!isOpen && !activeNotification) || dialogContent === DialogContent.Chat
  );
  // Don't use rhythm for height
  // as Safari ios is too small
  const iconPaddingY = 'py-[7px]';
  $: iconPaddingX =
    variant === RouteVariant.Product ? 'px-rhythm-1' : 'px-rhythm1';

  $: {
    switch ($currentRouteKeyStore) {
      case MAIN_NAVIGATION_ROUTER_KEY.CART:
      case MAIN_NAVIGATION_ROUTER_KEY.CHECKOUT:
      case MAIN_NAVIGATION_ROUTER_KEY.BLOG:
        activeDialog.set(DialogContent.None);
        variant = RouteVariant.None;
        break;
      case MAIN_NAVIGATION_ROUTER_KEY.PRODUCT:
        variant = RouteVariant.Product;
        break;
      case MAIN_NAVIGATION_ROUTER_KEY.MULTIFUNCTIONAL_LANDING:
        variant = RouteVariant.GuidedCartFlow;
        break;
      default:
        variant = RouteVariant.Default;
        break;
    }
  }

  $: {
    if ($productQuickViewSelectedStore) {
      activeDialog.set(DialogContent.QuickProductView);
    }
  }

  $: {
    if ($visible && !$mobileNavigationLoaded) {
      mobileNavigationLoaded.set(true);
    }
  }

  onMount(() => {
    appEventDispatcher.dispatch(AppEventName.CommsMenuMounted, {
      device: commsDevice
    });
  });

  onDestroy(() => {
    appEventDispatcher.dispatch(AppEventName.CommsMenuUnmounted, {
      device: commsDevice
    });
  });
</script>

{#if $visible}
  <ChatwootIntegration />

  <!-- None Variant means it's hidden. But show if a dialog is open (e.g.: user opens with menu icon in top var) -->
  {#if variant !== RouteVariant.None || $isActiveDialogOpen}
    <DialogBackdrop />
    <!-- NOTE: don't use top-[100dvh-75px] as the creates bad transition in Safari mobile with url bar and keyboard -->
    <div
      class="fixed bottom-10 z-70 flex w-screen justify-center lg:hidden"
      transition:fly={{ y: 50 }}
    >
      <!-- DISMISS BUTTONS -->
      <div class="flex-cnt absolute inset-x-0 -bottom-7 z-70">
        <DismissButton />
        <DismissNotificationButton
          withCountdown={notificationDismissible}
          handler={notificationHandler}
        />
      </div>

      <!-- POPOVERS -->
      <CartPopover />
      <GuidedCartPopOver content={guidedCartContent} />
      <SkuSelectorPopOver />
      <QuickProductViewPopOver
        on:navigation={() => activeDialog.set(DialogContent.None)}
      />
      <NavigationPopOver />
      <NotificationPopOver
        handler={notificationHandler}
        on:navigation={() =>
          notificationHandler.dismissById($activeNotification.id)}
        on:active={() => (notificationDismissible = false)}
        on:done={() => (notificationDismissible = true)}
      />

      <!-- BUTTONS -->
      {#if $shouldDisplayButtons && variant === RouteVariant.GuidedCartFlow}
        <div
          transition:scale
          class={makeClassNames(
            'flex-cnt bottom-1 w-screen',
            commonButtonClassNames
          )}
        >
          <GuidedCartButton />
        </div>
      {/if}

      {#if $shouldDisplayButtons && variant !== RouteVariant.GuidedCartFlow}
        <ul
          role="menu"
          transition:scale
          class={makeClassNames(
            '-bottom-4',
            commonButtonClassNames,
            'material-shadow-4 flex-cnt cursor-pointer text-primary',
            'rounded-full border-2 border-primary bg-pearl-100'
          )}
        >
          <li role="menuitem" class={secondaryButtonClassNames}>
            {#if variant === RouteVariant.Product}
              <AddToCartButton
                className={makeClassNames('px-rhythm0', iconPaddingY)}
              />
            {:else}
              <button
                type="button"
                class={makeClassNames(iconPaddingX, iconPaddingY, 'flex-cnt')}
                on:click={() => activeDialog.set(DialogContent.NavigationMenu)}
              >
                <MenuIcon className="stroke-primary h-6 w-6" />
              </button>
            {/if}
          </li>
          <!-- Central Icon Whitespace -->
          <div class={makeClassNames(iconPaddingY, 'px-rhythm1')} />
          <li
            transition:fly={{ y: 100 }}
            class={makeClassNames(
              iconPaddingY,
              'flex-cnt absolute inset-x-0 mx-auto rounded-full text-primary transition-transform transform-gpu',
              variant === RouteVariant.Product
                ? 'translate-x-9'
                : 'translate-x-0'
            )}
            role="menuitem"
            aria-expanded="false"
            aria-haspopup="true"
          >
            <MainCTA />
          </li>
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <li
            class={makeClassNames(
              iconPaddingY,
              iconPaddingX,
              secondaryButtonClassNames
            )}
            role="menuitem"
            aria-expanded={$activeDialog === DialogContent.Cart}
            aria-haspopup="true"
            aria-label="Open cart summary"
            on:click={() => activeDialog.set(DialogContent.Cart)}
          >
            <CartIcon
              labelId="mobile-comms-cart"
              className="w-6 h-6"
              size="sm"
            />
          </li>
        </ul>
      {/if}
    </div>
  {/if}
{/if}
