<script lang="ts">
  import { DialogContent } from '../../state';
  import PopOver from '../PopOver.svelte';
  import ProductSelection from '../../../components/guided-cart/ProductSelection.svelte';
  import SizeSelection from '../../../components/guided-cart/SizeSelection.svelte';
  import {
    activeStep,
    GuidedCartStep
  } from '../../../components/guided-cart/state';
  import StorageSelection from '../../../components/guided-cart/StorageSelection.svelte';
  import type { GuidedCartContent } from '../../../components/guided-cart/types';

  export let content: GuidedCartContent;
</script>

<PopOver
  className="max-h-screen-85 overflow-y-auto"
  content={DialogContent.GuidedCartFlow}
>
  {#if $activeStep === GuidedCartStep.Product || $activeStep === GuidedCartStep.Complete}
    <ProductSelection
      heading={content.productHeading}
      headline={content.productHeadline}
      CTAActive={content.productCTAActive}
      CTADisabled={content.productCTADisabled}
    />
  {:else if $activeStep === GuidedCartStep.Variant}
    <SizeSelection
      heading={content.variantHeading}
      headline={content.variantHeadline}
      CTAActive={content.variantCTAActive}
      CTADisabled={content.variantCTADisabled}
    />
  {:else if $activeStep === GuidedCartStep.Offer}
    <StorageSelection
      heading={content.offerHeading}
      headline={content.offerHeadline}
      CTAActive={content.offerCTAActive}
      CTADisabled={content.offerCTADisabled}
      acceptName={content.offerAcceptName}
      acceptDescription={content.offerAcceptDescription}
      declineName={content.offerDeclineName}
      video={content.offerVideo}
    />
  {/if}
</PopOver>
