<script lang="ts">
  /**
   * Orchestrator of notifications that takes
   * one notification at a time, even if it's an error.
   *
   * This differs from desktop, where stackability is a feature
   * due to bigger screens
   */
  import NotificationHandler from '@svelte/lib/notifications/notificationHandler';
  import CartNotification from './cart/CartNotification.svelte';
  import NewsletterPromptNotification from './newsletter-prompt/NewsletterPromptNotification.svelte';
  import { NotificationKind } from '@svelte/lib/notifications/types';
  import { createEventDispatcher } from 'svelte';
  import ErrorPopOver from './error/ErrorPopOver.svelte';
  import { isActiveDialogOpen } from '../../state';

  type EventMap = {
    done: undefined;
    active: undefined;
  };

  export let handler: NotificationHandler;
  const dispatch = createEventDispatcher<EventMap>();
  const currentNotificationStore = handler.nextNotification$;
  let isActive = false;
  $: notification = $currentNotificationStore;

  $: {
    if (isActive && !notification) {
      dispatch('done');
      isActive = false;
    } else if (!isActive && notification) {
      isActive = true;
    }
  }
</script>

{#if !$isActiveDialogOpen}
  {#if notification?.kind === NotificationKind.AddedToCart}
    <CartNotification on:navigation {notification} />
  {:else if notification?.kind === NotificationKind.NewsletterPrompt}
    <NewsletterPromptNotification on:active {notification} />
  {:else if notification?.kind === NotificationKind.Error}
    <ErrorPopOver error={notification} />
  {/if}
{/if}
