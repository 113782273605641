<script lang="ts">
  import { DialogContent, activeDialog } from '../../state';
  import ChatCTA from './ChatCTA.svelte';
  import { makeClassNames } from 'lib/util';
  import FlipComponent from '@svelte/components/molecules/animations/FlipComponent.svelte';
  import { getAppEventDispatcher } from '@svelte/lib/events/context';
  import { AppEventName } from 'lib/events/contracts';
  import { onDestroy } from 'svelte';
  import { get } from 'svelte/store';

  const appEventDispatcher = getAppEventDispatcher();
  const flipped = false;

  const unsubscriptions: Array<() => void> = [
    appEventDispatcher.subscribe(AppEventName.OpenedChat, () =>
      activeDialog.set(DialogContent.Chat)
    ),
    appEventDispatcher.subscribe(AppEventName.ClosedChat, () =>
      activeDialog.set(DialogContent.None)
    )
  ];

  function toggleContent() {
    const current = get(activeDialog);
    switch (current) {
      case DialogContent.None:
        appEventDispatcher.dispatch(AppEventName.OpenedChat);
        break;
      case DialogContent.Chat:
        appEventDispatcher.dispatch(AppEventName.ClosedChat);
        break;
      default:
        break;
    }
  }

  onDestroy(() => {
    for (const unsubscribe of unsubscriptions) {
      unsubscribe();
    }
  });
</script>

<button on:click={toggleContent} aria-label="open chat">
  <!-- NOTE: don't use rhythm for dimensions, as ios Safari would be to small -->
  <FlipComponent
    className={makeClassNames('h-14 w-14')}
    frontBg="bg-pearl-100"
    backBg="bg-primary"
    frontClassNames="rounded-full"
    backClassNames="border-2 border-secondary rounded-full text-secondary"
    {flipped}
  >
    <ChatCTA slot="front" />
    <div class="text-f1 font-semibold" slot="back">X</div>
  </FlipComponent>
</button>
