<script lang="ts">
  import { makeClassNames } from 'lib/util';
  import { fade } from 'svelte/transition';
  import { DialogContent, activeDialog, isActiveDialogOpen } from '../../state';
  import {
    bgColour,
    buttonBorderClassNames,
    buttonClassNames
  } from '../styles';
</script>

{#if $isActiveDialogOpen && $activeDialog !== DialogContent.Chat}
  <button
    in:fade={{ duration: 300 }}
    out:fade={{ duration: 200 }}
    type="button"
    on:click={() => activeDialog.set(DialogContent.None)}
    class={makeClassNames(buttonClassNames, buttonBorderClassNames, bgColour())}
  >
    X
  </button>
{/if}
