<script lang="ts">
  import { AppSiteNavWithLocale } from 'gatsby/types';
  import WithContexts from '../WithContexts.svelte';
  import MobileCommsMenu from '@svelte/features/comms-menu/mobile/MobileCommsMenu.svelte';
  import type { GuidedCartContent } from '@svelte/features/comms-menu/components/guided-cart/types';

  export let appNavTree: AppSiteNavWithLocale;
  export let guidedCartContent: GuidedCartContent;
</script>

<WithContexts {appNavTree}><MobileCommsMenu {guidedCartContent} /></WithContexts
>
